import $ from 'jquery-custom';
import { View } from 'backbone';

const AssignmentModalView = View.extend({
  open: function (url, afterOpen) {
    const $modal = $(require('bootstrap/modal.ejs')());
    const height = window.innerHeight - 200; // TODO: smarter iframe height calculation (this influences the height of the modal
    const $iframe = $('<iframe/>', {
      allowfullscreen: null,
      class: 'assignment-modal',
      frameborder: 0,
      height: `${height}px`,
      hspace: 0,
      mozallowfullscreen: null,
      name: 'assignment-modal',
      scrolling: 'auto',
      src: url,
      vspace: 0,
      webkitallowfullscreen: null,
      width: "100%",
    });

    // if an edit url then add confirmation when user clicks the dismiss button
    if (url.match(/\/edit/gi)) {
      $modal.find('[data-dismiss=modal]').on('click', this.confirmCancelModal);
    }

    $modal.one('hidden.bs.modal', function (e) {
      $(e.currentTarget).modal('dispose');
      $(e.currentTarget).remove();
    });

    // Test access via ajax so our global ajax session expiration handler can
    // intercept the request if necessary
    $.ajax({
      type: "HEAD",
      async: true,
      url: url
    }).done(function () {
      if ($('.assignment-modal').length > 0) {
	// If the user rapid-double-clicks on any of the links that cause an
	// AssignmentModal to open, a) that's bad and we should probably try to
	// prevent it, because extra server load and possible side effects
	// (like distributing extra OnDemandToDos), but also the view in the
	// browser will get strange: modals upon modals. So let's at least
	// ameliorate that on the back-side here, and we can work on ways to
	// prevent it on the front-side next.
        return;
      }
      $iframe.
        appendTo($modal.find('.modal-body')).
        on('load', function (e) {
          const title = $(e.target).contents().find('.modal-title').html();

          $modal.find('.modal-title').html(title);
          $modal.modal('show');
        });

      $modal.appendTo('body');

      if (typeof afterOpen === 'function') {
        afterOpen();
      }
    });
  },

  confirmCancelModal: function (e) {
    if (!window.confirm("This ToDo is about to be closed and all unsaved work will be lost. Click Cancel to return to the ToDo. Click OK to close the ToDo without saving work.")) {
      e.stopPropagation();
      e.preventDefault();
    }
  }
});

export default AssignmentModalView;
