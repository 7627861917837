import $ from 'jquery-custom';
import contentUiCss from '!!css-loader!tinymce/skins/ui/oxide/content.min.css';
import contentCss from '!!css-loader!tinymce/skins/content/default/content.min.css';

// cache bust manifest.json based on our VERSION file
import manifestVersion from '!!raw-loader!../../VERSION';

$.getJSON(`${__webpack_public_path__}manifest.json?version=${manifestVersion}`, function (manifest) {
  const tinymce_defaults = {
    skin: false,
    branding: false,
    content_css : [
      manifest['bootstrap-custom.css'],
      manifest['screen.css'],
      manifest['tinymce-custom.css']
    ],
    content_style: contentUiCss.toString() + '\n' + contentCss.toString(),
    plugins: "link table paste lists",
    width: "99%",
    height : 320,
    menubar : false,
    setup: function (editor) {
      const getCurrentBlock = function () {
        // Start with the smallest element containing the entire selection.
        let node = editor.selection.getNode();
        // Expand outward to nearest enclosing block element.
        const blockElements = editor.schema.getBlockElements();
        while (node && !blockElements[node.nodeName]) {
          node = node.parentElement;
        }
        // Will return null if no enclosing block element.
        return node;
      };
      const removeBlockIndent = function (node, indentClassBase) {
        // Find and remove any existing indentation class belonging to our block.
        const indentClassRegExp = RegExp("\\b" + indentClassBase + "(\\d)\\b");
        const indentClassMatch = indentClassRegExp.exec(node.className);
        if (indentClassMatch) {
          node.classList.remove(indentClassMatch[0]);
        }
        // Return the pre-existing indentation level.
        return indentClassMatch ? parseInt(indentClassMatch[1], 10) : 0;
      };
      const setBlockIndent = function (node, indentClassBase, indentLevel) {
        if (indentLevel) {
          node.classList.add(indentClassBase + indentLevel);
        }
      };
      const changeIndentation = function (direction) {
        const indentClassBase = "text-indent-";
        const minIndentLevel = 0;
        const maxIndentLevel = 6;

        const block = getCurrentBlock();
        if (block) {
          let indentLevel = removeBlockIndent(block, indentClassBase);
          if (direction === "indent") {
            indentLevel = Math.min((indentLevel + 1), maxIndentLevel);
          } else {
            indentLevel = Math.max((indentLevel - 1), minIndentLevel);
          }
          setBlockIndent(block, indentClassBase, indentLevel);
        }
      };
      editor.ui.registry.addButton("indentByClass", {
        icon: 'indent',
        onAction: function (_) {
          changeIndentation("indent");
        }
      });
      editor.ui.registry.addButton("outdentByClass", {
        icon: 'outdent',
        onAction: function (_) {
          changeIndentation("outdent");
        }
      });
    },
    toolbar: "styleselect | bold italic | link unlink | bullist numlist indentByClass outdentByClass | table | removeformat",
    table_default_attributes: {
      "class": 'table table-bordered'
    },
    table_appearance_options: false,
    table_advtab: false,
    table_cell_advtab: false,
    table_row_advtab: false,
    table_class_list: [
      {title: 'plain', value: 'table'},
      {title: 'bordered', value: 'table table-bordered'},
    ],
    formats: {
      // Force replacement of ALL classes when changing alignment.
      alignleft: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', attributes: { class: 'text-left' } },
      aligncenter: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', attributes: { class: 'text-center' } },
      alignright: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', attributes: { class: 'text-right' } },
      alignjustify: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', attributes: { class: 'text-justify' } },
      strikethrough: { inline: 'del' }
    },
    style_formats: [
      {title: "Headers", items: [
        {title: "Header 1", format: "h1"},
        {title: "Header 2", format: "h2"},
        {title: "Header 3", format: "h3"},
        {title: "Header 4", format: "h4"},
        {title: "Header 5", format: "h5"},
        {title: "Header 6", format: "h6"}
      ]},
      {title: "Inline", items: [
        {title: "Bold", icon: "bold", format: "bold"},
        {title: "Italic", icon: "italic", format: "italic"},
        {title: "Strikethrough", icon: "strikethrough", format: "strikethrough"},
        {title: "Superscript", icon: "superscript", format: "superscript"},
        {title: "Subscript", icon: "subscript", format: "subscript"}
      ]},
      {title: "Alignment", items: [
        {title: "Left", icon: "alignleft", format: "alignleft"},
        {title: "Center", icon: "aligncenter", format: "aligncenter"},
        {title: "Right", icon: "alignright", format: "alignright"},
        {title: "Justify", icon: "alignjustify", format: "alignjustify"}
      ]}
    ],
    paste_postprocess: function (plugin, args) {
      $(args.node).find('table').addClass('table');
      $(args.node).find('[style]').removeAttr('style');
    },
    relative_urls : false
  };

  const tinymce_disabled_defaults = $.extend({}, tinymce_defaults, {readonly: 1});

  $.fn.controlTinyMce = function () {
    $(this).each(function (index, textarea) {
      import(/* webpackChunkName: "tinymce_loader" */ 'tinymce_loader').
        then(module => {
          const tinymce = module.default;
          const version = `${tinymce.majorVersion}.${tinymce.minorVersion}`;
          const config = $.extend({}, tinymce_defaults, {target: textarea, cache_suffix: `?version=${version}`});

          tinymce.init(config);
        });
    });
  };

  $.fn.controlTinyMceDisabled = function () {
    $(this).each(function (index, textarea) {
      import(/* webpackChunkName: "tinymce_loader" */ 'tinymce_loader').
        then(module => {
          const tinymce = module.default;
          const version = `${tinymce.majorVersion}.${tinymce.minorVersion}`;
          const config = $.extend({}, tinymce_disabled_defaults, {target: textarea, cache_suffix: `?version=${version}`});

          tinymce.init(config);
        });
    });
  };

  $(function () {
    $('form.disabled-form textarea.mceEditor').controlTinyMceDisabled();
    $('form:not(.disabled-form) textarea.mceEditor').controlTinyMce();
  });
});
