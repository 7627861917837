import $ from 'jquery-custom';

// Assumes color is defined in _color_examples.scss using the following
// pattern:
// .<color>-bg { background-color: ...; }
//
const getColor = function (color) {
  const elem = $('<div class="' + color + '-bg" />').hide().appendTo('body');
  const rgb = elem.css('background-color');

  elem.remove();
  return rgb;
};

export default getColor;
