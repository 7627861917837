import $ from 'jquery-custom';
import _ from 'underscore';
const moment = require('moment');
import ControlToDo from 'sandbox/control_to_do';

const _date_helpers = {
  toISODateString: function (date) {
    return moment(date).format('YYYY-MM-DD');
  },

  add: function (date, dayCount) {
    return moment(date).add(dayCount, 'days');
  }
};

ControlToDo.modules.date_helpers = ControlToDo.func_factory('ControlToDo.modules.date_helpers', function date_helpers(box) {
  box.date_helpers = box.date_helpers || {};

  box.date_helpers.toISODateString = ControlToDo.func_factory('date_helpers.toISODateString', _date_helpers.toISODateString);
  box.date_helpers.add = ControlToDo.func_factory('date_helpers.add', _date_helpers.add);
});

const _custom_fields = (function () {
  function dataIdSelector(dataId) {
    return '[data-id=' + dataId + ']';
  }

  function getForm() {
    return $('form.assignment.assignment_edit');
  }

  function getTabs() {
    return getForm().closest('div#tsk_task_assignment_tabs');
  }

  function getTab(dataId) {
    return getTabs().find('div' + dataIdSelector(dataId));
  }

  function getField(dataId) {
    return getForm().find(':input' + dataIdSelector(dataId));
  }

  // e.g. a text display
  function getDisplayField(dataId) {
    return getForm().find(dataIdSelector(dataId) + ' div');
  }

  function getWrapper(dataId) {
    return getForm().find('li' + dataIdSelector(dataId));
  }

  function closestWrapper($els) {
    return $els.closest('li');
  }

  function getFieldset(dataId) {
    return getForm().find('li' + dataIdSelector(dataId));
  }

  // This sets an object as the return message to the
  // server. This should be an array and should not be malformed
  // such that JSON.stringify will puke.
  function setMessages(object) {
    const msg_block = getField('server_message');
    let json;
    if (msg_block) {
      json = JSON.stringify(object);
      msg_block.val(json);
    } else {
      console.error("No server_message HTML element was found when attempting to set the server message.");
    }
    return json;
  }

  function getMessages() {
    let parsedObject;
    const val = getField('server_message').val();

    if (_(val).isEmpty()) {
      parsedObject = [];
    } else {
      parsedObject = JSON.parse(val);
    }

    return parsedObject;
  }

  function messagesWithoutID(messageID) {
    const existingMessages = getMessages();
    const filteredMessages = _(existingMessages).reject(function (message) {
      return message.id === messageID;
    });

    return filteredMessages;
  }

  // tl;dr: be careful when changing the messageID that you are using.
  //
  // Scenario:
  //
  //   * We're using messageID `distribute_to_do_foo`
  //   * User starts a ToDo that uses that messageID and then saves it for later
  //   * We depoy changes to sandbox.js that change the messageID to `distribute_foo_to_do`
  //   * The user resumes their ToDo
  //   * We're now using messageID `distribute_to_do_foo`
  //   * Now the server message has both `distribute_to_do_foo` and `distribute_foo_to_do`
  //   * Two ToDos would be distributed instead of one, which is a bug
  function pushMessage(messageID, newMessage) {
    const filteredMessages = messagesWithoutID(messageID);

    newMessage.id = messageID;
    filteredMessages.push(newMessage);

    setMessages(filteredMessages);
  }

  function removeMessage(messageID) {
    const filteredMessages = messagesWithoutID(messageID);

    setMessages(filteredMessages);
  }

  return {
    getForm: getForm,
    getTabs: getTabs,
    getTab: getTab,
    getField: getField,
    getDisplayField: getDisplayField,
    getWrapper: getWrapper,
    closestWrapper: closestWrapper,
    getFieldset: getFieldset,
    setMessages: setMessages,
    getMessages: getMessages,
    pushMessage: pushMessage,
    removeMessage: removeMessage
  };
}());

// The `custom_fields` module contains the logic needed to
// manipulate custom fields in ToDos. Use a utility function
// *whenever* possible as they abstract many of the underlying
// details away from clients of the API. Trying to grab too many
// form elements directly increases code fragility and can easily
// lead to breakage if a CSS selector ceases to be valid. These
// are here for a reason.
ControlToDo.modules.custom_fields = ControlToDo.func_factory('ControlToDo.modules.custom_fields', function custom_fields(box) {
  box.custom_fields = box.custom_fields || {};

  box.custom_fields.getForm = ControlToDo.func_factory('custom_fields.getForm', _custom_fields.getForm);
  box.custom_fields.getTabs = ControlToDo.func_factory('custom_fields.getTabs', _custom_fields.getTabs);
  box.custom_fields.getTab = ControlToDo.func_factory('custom_fields.getTab', _custom_fields.getTab);
  box.custom_fields.getField = ControlToDo.func_factory('custom_fields.getField', _custom_fields.getField);
  box.custom_fields.getDisplayField = ControlToDo.func_factory('custom_fields.getDisplayField', _custom_fields.getDisplayField);
  box.custom_fields.getWrapper = ControlToDo.func_factory('custom_fields.getWrapper', _custom_fields.getWrapper);
  box.custom_fields.closestWrapper = ControlToDo.func_factory('custom_fields.closestWrapper', _custom_fields.closestWrapper);
  box.custom_fields.getFieldset = ControlToDo.func_factory('custom_fields.getFieldset', _custom_fields.getFieldset);
  box.custom_fields.message = ControlToDo.func_factory('custom_fields.message', _custom_fields.setMessages); // alias
  box.custom_fields.setMessages = ControlToDo.func_factory('custom_fields.setMessages', _custom_fields.setMessages);
  box.custom_fields.getMessages = ControlToDo.func_factory('custom_fields.getMessages', _custom_fields.getMessages);
  box.custom_fields.pushMessage = ControlToDo.func_factory('custom_fields.pushMessage', _custom_fields.pushMessage);
  box.custom_fields.removeMessage = ControlToDo.func_factory('custom_fields.removeMessage', _custom_fields.removeMessage);
});


ControlToDo.modules.todo_helpers = ControlToDo.func_factory('ControlToDo.modules.todo_helpers', function todo_helpers(box) {
  ControlToDo.modules.custom_fields(box);

  // NOTE: Can only handle one `server_message` at a time.
  function distributeToDo(opts) {
    const form = _custom_fields.getForm();
    const userID = $(form.find('select[data-id=' + opts.selectID + ']')).val();

    if (userID === '') {
      _custom_fields.setMessages([]);
    } else {
      _custom_fields.setMessages([{
        action: 'distribute_todo',
        params: {
          todo_slug: opts.todoID,
          assignees: [userID],
          due_date: _date_helpers.toISODateString(_date_helpers.add(new Date(), opts.dueDateOffset))
        }
      }]);
    }
  }

  function setMessageToCreateSubjectAssociationRecord() {
    function helpSetServerMessage() {
      const customFieldIDs = [];
      const $updatesAttributeFields = box.custom_fields.getForm().find('*[data-subject-association]');

      $updatesAttributeFields.each(function () {
        customFieldIDs.push($(this).data('custom-field-id'));
      });

      const messageID = 'create_subject_association_record_' + customFieldIDs.sort().join('_');

      if (customFieldIDs.length !== 0) {
        box.custom_fields.pushMessage(messageID, {
          action: 'create_subject_association_record',
          params: { custom_field_ids: customFieldIDs }
        });
      }
    }

    $('#tsk_task_assignment_tabs').on(
      'control:todo:before_save',
      helpSetServerMessage
    );

    helpSetServerMessage();
  }

  function setMessageToUpdateSubject() {
    function helpSetServerMessage() {
      const $updatesAttributeFields = box.custom_fields.getForm().find('*[data-updates-attribute]');

      $updatesAttributeFields.each(function () {
        const $this = $(this);
        const customFieldID = $this.data('custom-field-id');
        const updateSubjectMessageID = 'update_subject_' + customFieldID;

        box.custom_fields.pushMessage(updateSubjectMessageID, {
          action: 'update_subject',
          params: { custom_field_id: customFieldID }
        });
      });
    }

    $('#tsk_task_assignment_tabs').on(
      'control:todo:before_save',
      helpSetServerMessage
    );

    helpSetServerMessage();
  }

  function initShowHide() {
    // Uses these data-* attributes:
    //
    // Implemented (only for radio buttons select boxes):
    //
    //     {
    //       "show-if": {
    //         "id": "to_do_script_id",
    //         "conditions": {
    //           "eq": "foo"
    //         }
    //       }
    //     }
    //
    // Other situations we have considered:
    //
    //     {
    //       "show-if": [
    //         {
    //           "id": "to_do_script_id_1",
    //           "conditions": {
    //             "eq": "Yes"
    //           }
    //         },
    //         {
    //           "id": "to_do_script_id_2",
    //           "conditions": {
    //             "eq": "Yes"
    //           }
    //         }
    //       ]
    //     }
    //
    // Original request from claflamme 2015-06-17 1:44 PM Central:
    //
    // > @boakes: question about show/hide. if i have four Yes/No questions, can
    // > i show a fieldset based on if they answered Yes to any of those four
    // > questions. so using "or" logic in the show/hide metadata?  let me know
    // > if that's possible or an easy addition.
    //
    // Conditions that we have discussed:
    //
    //     "conditions": {
    //       "eq": "foo"
    //     }
    //
    //     "conditions": {
    //       // the value of a checkbox is always its "value" attribute, not
    //       // whether it's checked or not
    //       "checked": true
    //     }
    //
    //     "conditions": {
    //       "gt": 1
    //     }
    //
    //     "conditions": {
    //       "all": {
    //         "gt": 1,
    //         "lt": 5
    //       }
    //     }
    //
    //     "conditions": {
    //       "any": {
    //         "eq": "foo",
    //         "eq": "bar"
    //       }
    //     }
    //
    //     "conditions": {
    //       "not": { // or maybe "none"
    //         "all": {
    //           "gt": 1,
    //           "lt": 5
    //         }
    //       }
    //     }
    function toggle($observer, show) {
      // show/hide the $observer
      $observer.toggle(show);

      if ($observer.data('type') === 'CustomFieldFieldset') {
        // enable/disable the $observer so hidden :inputs are not sent with the form.
        // Disabled fieldsets implicitly disable their contained :inputs
        // https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#concept-fe-disabled
        //
        // use `children` instead of find to avoid disabling extra formtastic fieldsets that wrap radio buttons
        $observer.children('fieldset').prop('disabled', !show);

        // trigger the cascading change event for all nested :inputs
        $observer.find('li.custom-field-wrapper').trigger('control:show-hide:change');
      } else {
        // enable/disable the $observer so hidden :inputs are not sent with the form.
        $observer.find(':input').prop('disabled', !show);

        // trigger the cascading change event
        $observer.closest('li.custom-field-wrapper').trigger('control:show-hide:change');
      }
    }

    function parseConditions(conditions) {
      const operator = Object.keys(conditions)[0];
      const operand = conditions[operator].trim();

      return { operator: operator, operand: operand };
    }

    function shouldShow(operator, operand, observedValue) {
      let show;

      switch (operator) {
        case 'eq':
          show = (operand === observedValue);
          break;
        case 'neq':
          if (typeof observedValue === 'undefined' || observedValue === "") {
            show = false;
          } else {
            show = (operand !== observedValue);
          }

          break;
        default:
          show = false;
      }

      return show;
    }

    function findObservedValue($observed, selector) {
      // `is(':disabled')` is important here because it honors the html spec for inputs in disabled fieldsets
      // https://api.jquery.com/disabled-selector/#disabled1
      const value = $observed.find(selector).val();
      const observedValue = $observed.find(':input').is(':disabled') ? undefined : value?.trim();

      return observedValue;
    }

    function triggerObservedChange($observed, observedValue) {
      const observedId = $observed.find(":input[data-id]").data("id");
      const $observers = $("*[data-show-if-id=" + observedId + "]");
      $observers.trigger({type: "control:show-hide:observed-change", observedValue: observedValue});
    }

    function notifyShowHideObservers(event) {
      let observedValue;

      event.stopPropagation(); // containing .custom-field-wrappers (like fieldsets) should not be notified

      const $observed = $(this);
      const observedType = $observed.data('type');

      switch (observedType) {
        case 'CustomFieldSelect':
        case 'CustomFieldSelectFrom':
          observedValue = findObservedValue($observed, ':input');
          triggerObservedChange($observed, observedValue);
          break;
        case 'CustomFieldRadioButton':
          observedValue = findObservedValue($observed, ':input:checked');
          triggerObservedChange($observed, observedValue);
          break;
      }
    }

    function handleShowHideObservedChange(event) {
      event.stopPropagation(); // containing .custom-field-wrappers (like fieldsets) should not be notified

      const $observer = $(this);
      const parsedConditions = parseConditions($observer.data("show-if-conditions"));
      const show = shouldShow(parsedConditions.operator, parsedConditions.operand, event.observedValue);

      toggle($observer, show);
    }

    function initalizeShowHide() {
      // Trigger change events on "root" nodes to set initial state.
      $(this).find('form.assignment.assignment_edit li.custom-field-wrapper').not('*[data-show-if-id]').trigger('control:show-hide:change');
    }

    function triggerShowHideChange(event) {
      $(this).closest('li.custom-field-wrapper').trigger('control:show-hide:change');
    }

    // div#tsk_task_assignment_tabs
    box.custom_fields.getTabs().on('control:advance_tab control:todo:error', initalizeShowHide);
    box.custom_fields.getTabs().on('control:show-hide:change', 'li.custom-field-wrapper', notifyShowHideObservers);
    box.custom_fields.getTabs().on('control:show-hide:observed-change', '*[data-show-if-id]', handleShowHideObservedChange);
    box.custom_fields.getTabs().on('change', 'li.custom-field-wrapper :input', triggerShowHideChange);

    box.custom_fields.getTabs().one('control:show-hide:initialize', initalizeShowHide);
    box.custom_fields.getTabs().trigger('control:show-hide:initialize');
  }

  box.todo_helpers = box.todo_helpers || {};
  box.todo_helpers.distributeToDo = ControlToDo.func_factory('todo_helpers.distributeToDo', distributeToDo);
  box.todo_helpers.initShowHide = ControlToDo.func_factory('todo_helpers.initShowHide', initShowHide);
  box.todo_helpers.setCreateSubjectAssociationRecordMessage = ControlToDo.func_factory('todo_helpers.setCreateSubjectAssociationRecordMessage', setMessageToCreateSubjectAssociationRecord);
  box.todo_helpers.setMessageToCreateSubjectAssociationRecord = ControlToDo.func_factory('todo_helpers.setMessageToCreateSubjectAssociationRecord', setMessageToCreateSubjectAssociationRecord);
  box.todo_helpers.setUpdateSubjectMessage = ControlToDo.func_factory('todo_helpers.setUpdateSubjectMessage', setMessageToUpdateSubject);
  box.todo_helpers.setMessageToUpdateSubject = ControlToDo.func_factory('todo_helpers.setMessageToUpdateSubject', setMessageToUpdateSubject);
});
