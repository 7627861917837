import $ from 'jquery-custom';

const tooltips = {
  bind: function () {
    $('[data-toggle="tooltip"]').tooltip({ 
      container: 'body',
      boundary: 'window',
      trigger: 'hover click',
      delay: { show: 500, hide: 200 }
    });
  }
};

$(function () {
  tooltips.bind();
});

export default tooltips;
