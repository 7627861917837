import $ from 'jquery-custom';

/*doc
---
title: Control Typeahead
name: Control Typeahead
category: Javascript
---

## controlTypeahead

`chosen()` wrapper, that sets global defaults and allows configuration via
data attributes.

```html_example
<select multiple="" data-typeahead="true">
  <option value="1373">Monitoring (827)</option>
  <option value="10170">New contract (303)</option>
  <option value="352750">New Part Time Auditor Boarding  (35)</option>
  <option value="4847">New Employee (28)</option>
  <option value="325162">Vendor Risk Assessment (26)</option>
  <option value="192017">(Retired) Certified Control Freak Training (18)</option>
  <option value="192018">User Training (18)</option>
  <option value="335829">Employee Termination (15)</option>
  <option value="378344">Change Control for SaaS Offerings (1)</option>
</select>
```

Selects with a data-typeahead attribute will automatically become controlTypeahead

If you want to add it manually via JavaScript, call `controlTypeahead()` via jQuery selector:

```js_example
$(document).ready(function () {
  $('data-typeahead').controlTypeahead();
});
```

You can also pass an options object with whichever chosen options you would like

```js_example with options
$(document).ready(function () {
  $('data-typeahead').controlTypeahead({width: "50%", placeholder_text_multiple: "Here are your options"});
});
```
*/

$.fn.controlTypeahead = function (options) {
  const $el = $(this);
  const chosenOptions = $.extend(
    { width: '100%', placeholder_text_multiple: " ", placeholder_text_single: " ", search_contains: true },
    options
  );

  $el.chosen(chosenOptions);
};
