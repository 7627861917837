import $ from 'jquery-custom';
import _ from 'underscore';
const moment = require('moment');
require("footable/compiled/footable");

// Wrap the input event to stop placeholder changes from propagating in IE.
// Otherwise the placeholder change triggers an input event, which causes the
// value to get set to "", which triggers a placeholder update, which triggers
// an input event, ...
function onInputWrapper(callback) {
  if (!window.navigator.userAgent.match(/MSIE|Trident/)) return callback;

  return function (e) {
    const target = e.target;
    const active = (target == document.activeElement);

    if (!active || (target.placeholder && target.composition_started !== true)) {
      target.composition_started = active;

      if ((!active && target.tagName == 'TEXTAREA') || target.tagName == 'INPUT') {
        e.stopPropagation();
        e.preventDefault();
        return false;
      }
    }

    callback(e);
  };
}

window.FooTable.MyFiltering = window.FooTable.Filtering.extend({
  construct: function (instance) {
    this._super(instance);
    this.inputs = [];
    this.defaultValue = '';
    this.dateRangeRegex = new RegExp(/\d\d\/\d\d\/\d\d - \d\d\/\d\d\/\d\d/);
  },

  $create: function () {
    const self = this;

    this.inputs = self.ft.$el.find('thead td input[type=search]').on('input', { self: self }, onInputWrapper(self._onInputChange));
    $.merge(this.inputs, self.ft.$el.find('thead td input[type=text]').on('input', { self: self }, onInputWrapper(self._onInputChange)));
    $.merge(this.inputs, self.ft.$el.find('thead td input.date-range').on('change', { self: self }, self._onInputChange));
    $.merge(this.inputs, self.ft.$el.find('thead td :checkbox').on('change', { self: self }, self._onCheckboxChange));
    $.merge(this.inputs, self.ft.$el.find('thead td select').on('change', { self: self }, self._onInputChange));

    self.ft.$el.on('click', 'thead td .dropdown-menu', function (e) {
      // Keep dropdown-menu open when user clicks a filter by name
      e.stopPropagation();
    });

    this.clearBtn = self.ft.$el.find('.footable-filtering-clear button').on("click", { self: self }, self._clear);
    self.ft.$el.find('.dropdown-menu').on("click", ".uncheck-all", { self: self }, self._clearCheckboxes);

    this._super();
  },

  _clear: function (e) {
    const self = e.data.self;
    self.clear();
    self.clearBtn.prop("disabled", true);
  },

  _clearCheckboxes: function (e) {
    const self = e.data.self;
    const $input = $(this);
    const $checked = $input.closest('td').find(':checked');

    e.preventDefault();

    // NOTE: only trigger one change event so only one footable redraw happens
    $checked.prop("checked", false).first().trigger("change");
  },

  _ensure: function (filter, selectedColumns) {
    const preparedFilter = this._prepareFilter(filter);
    return this._super(preparedFilter, selectedColumns);
  },

  _prepareFilter: function (filter) {
    if (this.dateRangeRegex.test(filter.query)) {
      filter.query = new window.FooTable.DateRangeQuery(filter.query);
    }

    return filter;
  },

  _onCheckboxChange: function (e) {
    const self = e.data.self;
    const $input = $(this);
    const $checked = $input.closest('td').find(':checked');
    const columnIndex = $input.closest('td').index();
    const filterName = "column" + columnIndex.toString();
    const filterValue = $checked.map(function () {
      return '"' + $(this).val() + '"';
    }).get().join(' OR ');

    if (filterValue === self.defaultValue){ // if the value is "none" remove the filter
      self.removeFilter(filterName);
    } else { // otherwise add/update the filter.
      self.addFilter(filterName, filterValue, [columnIndex]);
    }

    self.filter();
  },

  timer: null,

  _onInputChange: function (e) {
    const self = e.data.self;
    const $input = $(e.target);
    const filterValue = '"' + $input.val() + '"'; // use quotes to hide phrase connectors from footable
    const columnIndex = $input.closest('td').index();
    const filterName = "column" + columnIndex.toString();
    const filter = self.find(filterName);

    if (self.timer){
      clearTimeout(self.timer);
    }

    self.timer = setTimeout(function () {
      if (filter && filter.query.val() === filterValue) {
        return; // Don't do anything unless the filter has changed
      }

      if (filterValue === '"' + self.defaultValue + '"'){ // if the value is "none" remove the filter
        self.removeFilter(filterName);
      } else { // otherwise add/update the filter.
        self.addFilter(filterName, filterValue, [columnIndex]);
      }

      self.filter().then(function () {
        $input.trigger("focus");
        $input[0].setSelectionRange($input.val().length, $input.val().length);
      });
    }, 500);
  },

  draw: function () {
    const self = this;
    let disableClearBtn = true;
    this._super();

    _.each(self.inputs, function (input) {
      const $input = $(input);
      const columnIndex = $input.closest('td').index();
      const filterName = "column" + columnIndex.toString();
      const filter = self.find(filterName);
      const now = moment();
      const $badge = $input.closest('td').find('.badge');
      const checkedCount = $input.closest('td').find(':checked').length;
      let queryValue;
      let newValue;

      if (filter instanceof window.FooTable.Filter){
        queryValue = filter.query.val();
        disableClearBtn = false;

        if ($input.is(':checkbox')) {

          // See https://api.jquery.com/jQuery.inArray for -1 explanation
          if ($.inArray($input.val(), queryValue.replace(/"/g, '').split(" OR ")) !== -1) {
            $input.prop('checked', true);
          }
        } else {
          newValue = queryValue.replace(/^"(.*)"$/, '$1');

          if ($input.val() !== newValue) {
            $input.val(newValue); // `replace` to remove quotes we added to hide phrase connectors from footable
          }
        }
      } else {
        if ($input.data('daterangepicker')) {
          $input.val(self.defaultValue);
          $input.data('daterangepicker').setStartDate(now);
          $input.data('daterangepicker').setEndDate(now);
        } else if ($input.is(":checkbox")) {
          $input.closest('td').find(':checked').prop('checked', false);
        } else {
          if ($input.val() !== self.defaultValue) {
            $input.val(self.defaultValue);
          }
        }
      }

      if (checkedCount > 0) {
        $badge.html(checkedCount);
      } else {
        $badge.html('');
      }
    });

    self.clearBtn.prop("disabled", disableClearBtn);
  }
});

window.FooTable.DateRangeQuery = window.FooTable.Query.extend({
  construct: function (dateRangeString, space, connectors, ignoreCase) {
    // TODO: get format string from <th>
    this._format = 'MM/DD/YY';

    this._super(dateRangeString, space, connectors, ignoreCase);
  },

  _parse: function () {
    const parsed = this._value.split(" - ");

    this.startDate = moment(parsed[0], this._format);
    this.endDate = moment(parsed[1], this._format);
  },

  match: function (str) {
    if(str.trim() === '-') return false;

    const date = moment(str, this._format);

    // NOTE: isBetween is exclusive by default, change to match other
    // range queries on platform (https://momentjs.com/docs/#/query/is-between/)
    return date.isBetween(this.startDate, this.endDate, null, '[]');
  }
});


$.fn.controlFooTable = function () {
  const isPdf = $('body').data('format') === 'pdf';

  if (!isPdf) {
    $(this).each(function (tableIndex, table) {
      const $table = $(table);
      const defaultOptions = {
        components: { filtering: window.FooTable.MyFiltering },
        paging: {
          enabled: true,
          size: $table.data('paging-size') || 20
        },
        sorting: { enabled: true },
        filtering: { enabled: true, connectors: false },
        state: { enabled: true },
        on: {
          "postinit.ft.table": function (e, ft) {
            // NOTE: The following is for bootstrap 4 compatibility

            ft.$el.find('.footable-filtering .form-inline').addClass('flex-row-reverse');
            ft.$el.find('.footable-filtering-search .input-group-btn').addClass('input-group-append');
            ft.$el.find('.footable-filtering-search .caret').addClass('fal fa-caret-down');
            ft.$el.find('.footable-filtering-search .dropdown-toggle').
              addClass('dropdown-toggle-split').
              attr('data-toggle', "dropdown").
              dropdown();
            ft.$el.find('.footable-filtering-search .dropdown-menu li').addClass('dropdown-item');
          }
        }
      };

      $table.footable(defaultOptions);
    });
  }
};

$(function () {
  $('.footable').controlFooTable();
});
