import _ from 'underscore';
import ControlToDo from 'sandbox/control_to_do';

ControlToDo.modules.math_helpers = ControlToDo.func_factory('ControlToDo.modules.math_helpers', function math_helpers(box) {
  const _math_helpers = function () {
    // Like `Math.round`, but allows you to specifiy the number of decimal places to show.
    function round(value, ndigits) {
      const n = Math.pow(10, ndigits);
      return Math.round(value * n) / n;
    }

    function add(l, r) {
      return l + r;
    }

    function sum(values) {
      return _.reduce(values, add, 0);
    }

    function weightedAverage(values, weights) {
      let weightedValues;

      if (values.length === weights.length) {
        weightedValues = _.map(values, function (v, i) {
          return weights[i] * v;
        });
        return sum(weightedValues) / sum(weights);
      } else {
        throw new Error('mismatch between length of values and weights');
      }
    }

    function average(values, options) {
      options = options || {};

      if (options.weights) {
        return weightedAverage(values, options.weights);
      } else {
        return sum(values) / values.length;
      }
    }

    return {
      sum: sum,
      average: average,
      avg: average,
      round: round
    };
  };

  box.math_helpers = box.math_helpers || {};
  box.math_helpers = ControlToDo.func_factory('math_helpers', _math_helpers());
});

