import $ from 'jquery-custom';
import _ from 'underscore';
import getColor from 'colors';

/*doc
---
title: Charts
name: charts
category: Javascript
---

We have a number of charts that are available with an HTML5 data attributes API.

Colors can be specified by name with `data-chart-colors` which uses the colors from the Continuity color palette as defined in `_variables.scss`.  Some common schemes are available, such as `bootstrap-green-yellow-red`

The data that will be charted is provided using `data-json`.  It's recommended that you use `HighChartsFormatter` for this purpose.  See `lib/high_charts_formatter.rb`.

## Supported Charts

Obligatory [XKCD reference](https://xkcd.com/688/):

![Self-Description](http://imgs.xkcd.com/comics/self_description.png)

### Pie Chart

A pie chart with an always-visible legend below the chart.

```html_example
<div class="pie-chart"
data-chart-colors='["red","green","blue"]'
data-json='[["Fraction of this image which is red",1],["Fraction of this image which is green",1],["Fraction of this image which is blue",1]]'
style="width: 100%;
height: 160px;">
</div>
```

### Compact Pie Chart

A pie chart without a legend.  The labels appear when hovering over the slices of the pie.

```html_example
<div class="compact-pie-chart"
data-chart-colors='["red","green","blue"]'
data-json='[["Fraction of this image which is red",1],["Fraction of this image which is green",1],["Fraction of this image which is blue",1]]'
style="width: 100%;
height: 160px;">
</div>
```

### Compact Pie Chart Legend

A pie chart with an always-visible legend to the right of the chart, which is better for some compact circumstances.

```html_example
<div class="compact-pie-chart-legend"
data-chart-colors='["red","green","blue"]'
data-json='[["Fraction of this image which is red",1],["Fraction of this image which is green",1],["Fraction of this image which is blue",1]]'
style="width: 100%;
height: 160px;">
</div>
```

### Column Chart

A bar chart that allows you to supply labels for the X axis.

```html_example
<div class="column-chart"
        data-haxis-labels-step="1"
        data-json='[["Jan", 1], ["Feb", 2], ["Mar", 3], ["Apr", 4], ["May", 5], ["Jun", 6], ["Jul", 7], ["Aug", 8], ["Sep", 9], ["Oct", 10], ["Nov", 11], ["Dec", 12]]'
        style="width: 100%; height: 180px;"></div>
```

### Gauge Chart

```html_example
<div class="gauge-chart"
  data-json="[99]"
  data-min="0"
  data-max="100"
  data-series-datalabels-format="{y}% there"
  style="width: 300px; height: 125px;">
</div>
```

## AJAX

If you have loaded content via AJAX and need to render charts, use this call:

```js_example
Charts.render();
```
*/

const Charts = {
  render: function render() {
    // Use risk-rating-colors from
    // scss/_variables.scss
    const red = getColor('red');
    const orange = getColor('orange');
    const yellow = getColor('yellow');
    const green = getColor('green');
    const grayDark = getColor('gray-dark');
    const grayDarker = getColor('gray-darker');
    const blueDark = getColor('blue-dark');
    const colorSchemes = {
        'bootstrap-green-red': [green, red],
        'bootstrap-green-yellow-red': [green, yellow, red],
        'bootstrap-risk-rating': [blueDark, green, yellow, orange, red, grayDark]
      };

    function elementColors($element) {
      let hexColors;

      hexColors = _($element.data('chart-colors')).map(function (colorName) {
        return getColor(colorName);
      });

      hexColors = _(hexColors).isEmpty() ? undefined : hexColors;

      return hexColors;
    }

    function setColors($element) {
      return elementColors($element) || colorSchemes[$element.data('chart-color-scheme')] || {};
    }

    // TODO: standardize on `.pie-chart`
    $('.pie_chart, .pie-chart').each(function (i, element) {
      const $element = $(element);
      const data = $element.data('json');

      const config = {
        colors: setColors($element),
        title: { text: $element.data('chart-title') || null },
        chart: {
          renderTo: element,
          defaultSeriesType: 'pie'
        },
        plotOptions: {
          pie: {
            dataLabels: { enabled: false },
            showInLegend: true
          }
        },
        series: [{ name: 'Series', data: data }]
      };

      import(/* webpackChunkName: "highcharts" */ 'highcharts').
        then(module => {
          const Highcharts = module.default;

          new Highcharts.Chart(config);
        });
    });

    $('.compact-pie-chart-legend').each(function (i, element) {
      const $element = $(element);
      const data = $element.data('json');

      const config = {
        colors: setColors($element),
        chart: {
          renderTo: element,
          defaultSeriesType: 'pie'
        },
        plotOptions: {
          pie: {
            size: '100%',
            dataLabels: { enabled: false },
            showInLegend: true
          }
        },
        title: {
          text: null
        },
        legend: {
          enabled: true,
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle',
          borderRadius: 0,
          borderWidth: 0
        },
        tooltip: {
          formatter: function () {
            return this.key.replace(/\s+/g, '<br>');
          }
        },
        series: [{ name: 'Series', data: data }]
      };

      import(/* webpackChunkName: "highcharts" */ 'highcharts').
        then(module => {
          const Highcharts = module.default;

          new Highcharts.Chart(config);
        });
    });

    $('.compact-pie-chart').each(function (i, element) {
      const $element = $(element);
      const data = $element.data('json');

      const config = {
        colors: setColors($element),
        chart: {
          renderTo: element,
          defaultSeriesType: 'pie'
        },
        plotOptions: {
          pie: {
            dataLabels: { enabled: false }
          },
          series: {
            enableMouseTracking: true
          }
        },
        title: {
          text: null
        },
        legend: {
          enabled: false
        },
        tooltip: {
          formatter: function () {
            return this.key.replace(/\s+/g, '<br>');
          }
        },
        series: [{
          name: 'Series',
          data: data
        }]
      };

      import(/* webpackChunkName: "highcharts" */ 'highcharts').
        then(module => {
          const Highcharts = module.default;

          new Highcharts.Chart(config);
        });
    });

    // Zombie?  It used to be used on Reports (distributed_to_dos_over_time) but that is no longer called.
    $('.time_chart').each(function (i, element) {
      const $element = $(element);
      const data = $element.data('json');
      const series_data = _(data).map(function (pair) {
          return [Date.parse(pair[0]), pair[1]];
        });

      const config = {
        title: { text: $element.data('chart-title') },
        chart: {
          renderTo: element
        },
        series: [{
          type: 'column',
          name: 'Series',
          data: series_data,
          pointStart: Date.parse($element.data('chart-start')),
          pointInterval: 24 * 3600 * 1000 // one day
        }],
        plotOptions: {
          series: {
            pointPadding: 0,
            groupPadding: 0,
            borderWidth: 0,
            shadow: false
          }
        },
        yAxis: {
          title: { text: $element.data('vaxis-title') },
          allowDecimals: false,
          min: 0
        },
        xAxis: {
          type: 'datetime',
          title: { text: $element.data('haxis-title') }
        },
        legend: { enabled: false }
      };

      import(/* webpackChunkName: "highcharts" */ 'highcharts').
        then(module => {
          const Highcharts = module.default;

          new Highcharts.Chart(config);
        });
    });

    $('.column_chart, .column-chart').each(function (i, element) {
      const $element = $(element);
      const data = $element.data('json');
      const categories = _(data).map(function (pair) {
          return pair[0].toString();
        });
      const series_data = _(data).map(function (pair) {
          return pair[1];
        });

      const config = {
        title: { text: $element.data('chart-title') || null },
        chart: {
          renderTo: element,
          defaultSeriesType: 'column',
          width: $element.data('width') || null,
          height: $element.data('height') || null
        },
        // TODO: graph two series on the same chart (needs labels, though):
        //
        //     series: [{ name: 'Test', data: [1, 2, 3, 4] }, { name: 'Declared', data: [4, 3, 2, 1] }],
        series: [{ name: 'Series', data: series_data }],
        yAxis: {
          title: { text: $element.data('vaxis-title') || null },
          allowDecimals: false,
          min: 0
        },
        xAxis: {
          categories: categories,
          title: { text: $element.data('haxis-title') || null },
          labels: {
            step: $element.data('haxis-labels-step') || 2,
            rotation: -45,
            align: 'right'
          }
        },
        legend: { enabled: false }
      };

      import(/* webpackChunkName: "highcharts" */ 'highcharts').
        then(module => {
          const Highcharts = module.default;

          new Highcharts.Chart(config);
        });
    });

    $('.gauge-chart').each(function (i, element) {
      import(/* webpackChunkName: "highcharts" */ 'highcharts').
        then(module => {
          const Highcharts = module.default;
          const $element = $(element);
          const data = $element.data('json');

          const config = {
            chart: {
              type: 'solidgauge',
              renderTo: element
            },

            title: null,
            pane: {
              center: ['50%', '85%'],
              size: '140%',
              startAngle: -90,
              endAngle: 90,
              background: {
                backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEEEEE',
                innerRadius: '60%',
                outerRadius: '100%',
                shape: 'arc'
              }
            },
            tooltip: {
              enabled: false
            },

            // the value axis
            yAxis: {
              stops: [
                [0.0, green],
                [0.25, yellow],
                [0.50, orange],
                [0.75, red]
              ],
              lineWidth: 0,
              minorTickInterval: null,
              tickPixelInterval: 400,
              tickWidth: 0,
              labels: {
                enabled: false
              },
              min: $element.data('min'),
              max: $element.data('max')
            },

            plotOptions: {
              solidgauge: {
                dataLabels: {
                  y: 5,
                  borderWidth: 0,
                  useHTML: true
                }
              }
            },
            series: [{
              data: data,
              dataLabels: {
                format: $element.data('series-datalabels-format')
              }
            }]
          };

          new Highcharts.Chart(config);
        });
    });
  }
};

export default Charts;

$(function () {
  Charts.render();
  $(document).on('shown.bs.modal', Charts.render);
});
