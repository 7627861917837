import $ from 'jquery-custom';
import Rails from '@rails/ujs';

// Automatically submit a form after a field is changed.
//
// Like autocomplete or autofill, but this is autosubmit.
// Currently only works on checkboxes as written, we'd like to extend this in
// the future
//
// [rel:save-on-change] It may be preferable to use buttonset for this in the
// future.  Product was fairly adamant that we use a checkbox for ignoring
// assets, when autosubmit was added.
$.fn.autosubmit = function () {
  const $input = $(this);
  const $form = $input.closest('form');
  let previousValue = $input.prop('checked');

  $form.on('confirm:complete', function (event) {
    const answer = event.detail[0];

    if (answer) {
      previousValue = $input.prop('checked');
    } else { // canceled, revert value
      $input.prop('checked', previousValue);
    }
  });

  $input.on("change", function () {
    Rails.fire($form[0], 'submit');
  });
};
