import $ from 'jquery-custom';

function checkboxes() {
  return $('div.checkbox_tree input[type=checkbox]');
}

function toggleSources() {
  return $('div.checkbox_tree [data-toggle-source-for]');
}

function toggleTargets() {
  return $('div.checkbox_tree [data-toggle-target-for]');
}

$(document).on('change', 'div.checkbox_tree input[type=checkbox]', function (event) {
  if ($(this).hasClass('trunk')) {
    $(this).
      parents('li').
      first().
      find('input[type=checkbox]').
      prop('checked', $(this).prop('checked'));
  }
});

$(document).on('click', '.select_all', function (event) {
  const checkboxes = checkboxes();

  checkboxes.prop('checked', true);
  event.preventDefault();
});

$(document).on('click', '.select_none', function (event) {
  const checkboxes = checkboxes();

  checkboxes.prop('checked', false);
  event.preventDefault();
});

$(document).on('click', '[data-toggle-all="show"]', function (event) {
  const toggleSources = toggleSources();
  const toggleTargets = toggleTargets();

  toggleSources.attr('title', 'Click to hide');
  toggleSources.find('i').toggleClass('fa-minus-square', true);
  toggleSources.find('i').toggleClass('fa-plus-square', false);
  toggleTargets.slideDown(function () {
    $(this).removeClass('hide');
  });
  event.preventDefault();
});

$(document).on('click', '[data-toggle-all="hide"]', function (event) {
  const toggleSources = toggleSources();
  const toggleTargets = toggleTargets();

  toggleSources.attr('title', 'Click to show');
  toggleSources.find('i').toggleClass('fa-minus-square', false);
  toggleSources.find('i').toggleClass('fa-plus-square', true);
  toggleTargets.slideUp();
  event.preventDefault();
});

$(document).on('click', '[data-toggle-source-for]', function (event) {
  const $clicked = $(event.currentTarget);
  const toggleId = $clicked.data('toggle-source-for');

  if ($clicked.attr('title') === 'Click to hide') {
    $('[data-toggle-target-for="' + toggleId + '"]').slideUp();
    $clicked.attr('title', 'Click to show');
    $clicked.find('i').toggleClass('fa-minus-square', false);
    $clicked.find('i').toggleClass('fa-plus-square', true);
  } else {
    $('[data-toggle-target-for="' + toggleId + '"]').slideDown(function () {
      $(this).removeClass('hide');
    });
    $clicked.attr('title', 'Click to hide');
    $clicked.find('i').toggleClass('fa-minus-square', true);
    $clicked.find('i').toggleClass('fa-plus-square', false);
  }
  event.preventDefault();
});
