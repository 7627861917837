import $ from 'jquery-custom';
import { initDatePicker, singleDefaults, initDateRangePicker, rangeDefaults } from "bootstrap-daterangepicker/jquery-daterangepicker";

$(function () {
  initDatePicker($('input.datepicker'), singleDefaults);
  initDateRangePicker($('input.date-range'), rangeDefaults);
});

$(document).on("shown.bs.tab shown.bs.modal tabsrouter:ajaxloaded ajax:error", function () {
  initDatePicker($('input.datepicker'), singleDefaults);
  initDateRangePicker($('input.date-range'), rangeDefaults);
});
