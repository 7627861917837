import $ from 'jquery-custom';
import ControlToDo from 'sandbox/control_to_do';
import { Model } from 'backbone';

ControlToDo.modules.assignment_info = ControlToDo.func_factory('ControlToDo.modules.assignment_info', function assignment_info(box) {
  const AssignmentInfo = Model.extend({});
  box.assignment_info = new AssignmentInfo();
  box.assignment_info.set($('*[data-assignment-json]').data('assignment-json'));
});

