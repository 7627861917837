import $ from 'jquery-custom';
import _ from 'underscore';
import 'tooltips';
import 'custom_jquery_plugins/autosubmit';
import 'custom_jquery_plugins/controlFooTable';
import 'custom_jquery_plugins/controlFlash';
import 'custom_jquery_plugins/controlTypeahead';
import autoFillToDo from 'debugTools/autoFillToDo';
window.autoFillToDo = autoFillToDo;
import ajax_flash from 'ajax_flash';
import "bootstrap-daterangepicker/global-init-daterangepicker";
import 'tinymce';
import tableOfContents from 'toc';
window.tableOfContents = tableOfContents;
import AssignmentModalView from 'assignments/assignment_modal_view';
import 'custom';

$(document).on('click', '.input-group-append', function () {
  const $faCalendar = $(this);
  $faCalendar.closest('.input-group').find('input').trigger("focus");
});

const changeBodyClassName = (function changeBodyClassName() {
  return function changeBodyClassName(new_class_name) {
    $('body').removeClass().addClass('bp').addClass(new_class_name);
  };
}());

(function application() {
  const typeaheadOptions = {};

  function toggleLoading(element) {
    element.parent().find('span.spinner').toggle();
  }

  // Zombie method? (try `git grep tab-ajax`)
  $(document).on('click', '[data-toggle="tab-ajax"]', function (e) {
    const $this = $(this);
    const url = $this.prop('href');
    const target = $this.data('target');

    e.preventDefault();

    $(target).html($('#spinner-template').html());
    $this.tab('show');

    $.get(url, function (data) {
      $(target).html(data);
    });
  });

  $(document).on('tabsrouter:showtab', function (e) {
    const $tabHeader = $(e.target);
    const $tabBody = $($tabHeader.data('target'));
    const url = $tabHeader.data('tab-ajax-source-url');

    if (url === undefined) { return; }

    $tabBody.html($('#spinner-template').html());

    $.get(url, function (htmlData) {
      $tabBody.html(htmlData);
    });
  });


  $(function dom_ready() {
    let availableTags;

    $(document).on('click', '.close_flash', function close_flash_click(event) {
      $(this).closest('div.flash_message').collapse('hide');
      return false;
    });

    /**
     * Update Fullname with First + Last name
     * TODO: Zombie? Try `git grep order_user_attributes_fullname` etc
     */
    const fullname = $('#order_user_attributes_fullname');
    const fn = $('#order_first_name');
    const ln = $('#order_last_name');
    $(document).on('change', fn, function () {
      fullname.val(fn.val() + ' ' + ln.val());
    });

    $(document).on('change', ln, function () {
      fullname.val(fn.val() + ' ' + ln.val());
    });

    $(document).on('change', fullname, function () {
      $(document).off('change', fn);
      $(document).off('change', ln);
    });

    // universal panel hide
    $(document).on('click', '[data-toggle-panel][data-toggle-panel!=false]', function (event) {
      event.preventDefault();
      $('#' + $(this).data('toggle-panel')).collapse('toggle');
    });

    $(document).on('click', ".lightview", function (event) {
      const $this = $(this);
      const $modal = $(require('bootstrap/modal.ejs')());

      $modal.one('hidden.bs.modal', function (event) {
        $(event.currentTarget).modal('dispose');
        $(event.currentTarget).remove();
      });

      $.ajax($this.attr('href')).
        done(function (data, textStatus, jqXHR) {
          const $response = $($.parseHTML(data)).filter("*");
          const title = $response.filter('.modal-title').html();

          $modal.find('.modal-title').html(title);
          $response.appendTo($modal.find('.modal-body'));
          $modal.
            appendTo('body').
            modal('show');
        });

      event.preventDefault();
    });

    $(document).on('click', ".open-assignment-modal", function (e) {
      e.preventDefault();

      const modal = new AssignmentModalView();
      modal.open($(this).attr('href'));
    });

    $(document).on('click', '.close-assignment-modal, [data-hide-lightbox=true]', function () {
      const $modal = $(".modal.show");

      $modal.modal('hide');
      return false;
    });

    $('[data-autosubmit]').autosubmit();

    // Update all inputs marked with the data attribute of typeahead to use
    // Control's typeahead library
    setTimeout(function () {
      // We *shouldn't* have to wrap this in a 0ms timeout, but if we don't,
      // then, when chosen initializes, the fake <input> it uses to simulate
      // the normal <input> will be too narrow, and the placeholder text will
      // get cut off. If we delay this a bit, it has enough time to initialize
      // whatever it needs to, and the <input> is wide enough.
      $('select[data-typeahead]').controlTypeahead(typeaheadOptions);
    }, 0);

    $(document).on("ajaxSuccess", function () {
      $('select[data-typeahead]').controlTypeahead(typeaheadOptions);
    });

    $(document).on("ajaxError", function () {
      $('select[data-typeahead]').controlTypeahead(typeaheadOptions);
    });
  });
}());


$(function () {
  const COUNTDOWN_TIME = 60; // 1 minute
  let countdownTime = COUNTDOWN_TIME;
  let countdownInterval;

  const resendButton = $('#resendButton');
  const mfaEmailTokenTimer = $('#mfa-email-token-timer');

  function startCountdown() {
    countdownInterval = setInterval(updateTimer, 1000);
  }

  function updateTimer() {
    if (countdownTime <= 0) {
      clearInterval(countdownInterval);
      resendButton.removeClass('disabled').prop('disabled', false).text('Resend');
      mfaEmailTokenTimer.text('');
    } else {
      const minutes = Math.floor(countdownTime / 60);
      let seconds = countdownTime % 60;
      seconds = seconds < 10 ? '0' + seconds : seconds;
      mfaEmailTokenTimer.text(`Resend the token in ${minutes}:${seconds} minutes`);
      countdownTime--;
    }
  }

  function handleResendButtonClick(event) {
    event.preventDefault();
    if (!resendButton.hasClass('disabled')) {
      $.ajax({
        url: '/users/resend_mfa_token',
        type: 'POST',
        success: function (response) {
          $('.flash-messages').hide();
          const alertContent = `<div class="alert alert-success alert-dismissible fade show" role="alert">
            <div>${response.message}</div>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>`;
          $('#message-container-alert').html(alertContent);
          resetCountdown();
        },
        error: function (xhr, status, error) {
          alert(`An error occurred: ${error}`);
        }
      });
    }
  }

  function resetCountdown() {
    countdownTime = COUNTDOWN_TIME;
    resendButton.addClass('disabled').prop('disabled', true).text('Resend');
    startCountdown();
  }

  function toggleMfaFields(elem) {
    const elements = $('.mfa-fields');
    if ($(elem).is(':checked')) {
      elements.show();
    } else {
      elements.hide();
      // $('input[name="mfa_option"]').prop('checked', false);
    }
  }

  startCountdown();
  resendButton.on('click', handleResendButtonClick);


  $(document).on('change', '#organization_multi_factor_authentication', function () {
    toggleMfaFields($(this));
  });
});

  $(document).on('click', '.scrollToTopButton', function () {
    $('html, body').animate({ scrollTop: 0 }, 'slow');
  });
  
