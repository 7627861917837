import $ from 'jquery-custom';
import _ from 'underscore';

const ajax_flash = function (type, flash) {
  const flashTemplate = _.template('<div class="row"><div class="alert alert-dismissible fade show alert-{{ key }} js--ajax-flash-message"><button type="button" class="close" data-dismiss="alert"><i class="fal fa-times"></i></button>{{ value }}</div></div>');
  const $flashRendered = $(flashTemplate({"key": type, "value": flash}));

  $('#flash').html($flashRendered);

  if (type === 'success' || type === 'info') {
    window.setTimeout(function () {
      $flashRendered.find(".alert").alert('close');
    }, 7500);
  }
};

export default ajax_flash;

$(function () {
  window.setTimeout(function () {
    $('.alert-success.js--flash-message, .alert-info.js--flash-message').alert('close');
  }, 7500);
});
