import $ from 'jquery-custom';
import getColor from 'colors';
/*doc
---
title: User Feedback
name: controlFlash
category: Javascript
---

## controlFlash

When you want to flash a DOM element to bring the user's attention to it. Think
of when an ajax HTTP post succeeds, or when you just copied some text to the
user's clipboard.

Usage:

```html_example
<button id='clicker'>Flash it</button>
<span id='flashy'>Watch me carefully</span>
```

```js_example
$(document).ready(function () {
  $('#clicker').click(function () {
    $('#flashy').controlFlash('success');
  });
});
```

When the button is clicked, it will turn the span next to it green (Continuity
green, specifically), and it'll fade back to its original color in 1 second.

```js_example
  $('.something_that_should_flash_red').controlFlash('error');
```

In this example, anything with the class `.something_that_should_flash_red` will be flashed the color `red` from our color palette.

(There is a known bug where if something flashes multiple times quickly, its originalColor may get stuck at an intermediate color.)

*/


$.fn.controlFlash = function (type) {
  type = type || 'success';

  $(this).each(function (index, el) {
    const $el = $(el);
    const originalColor = $el.css('backgroundColor');

    $el.removeClass('flash-error flash-success').addClass(`flash-${type} show`);

    setTimeout(function () {
      $el.removeClass('show');
    }, 2000);
  });
};
