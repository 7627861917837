import $ from 'jquery-custom';
import daterangepicker from 'bootstrap-daterangepicker/daterangepicker.js';
import "bootstrap-daterangepicker/daterangepicker.css";
import "bootstrap-daterangepicker/daterangepicker.scss";
const moment = require('moment');

export const rangeDefaults = {
  autoUpdateInput: false,
  buttonClasses: 'btn btn-sm',
  applyButtonClasses: 'btn-primary',
  cancelClass: 'btn-secondary',
  alwaysShowCalendars: true,
  ranges: {
    'Today': [moment(), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Calendar Quarter': [moment().startOf('quarter'), moment().endOf('quarter')],
    'Last Calendar Quarter': [moment().subtract(1, 'quarter').startOf('quarter'), moment().subtract(1, 'quarter').endOf('quarter')],
    'This Year': [moment().startOf('year'), moment().endOf('year')],
    'Last 365 Days': [moment().subtract(364, 'days'), moment()]
  },
  locale: {
    cancelLabel: 'Clear',
    format: 'MM/DD/YY'
  }
};

export const singleDefaults = {
  alwaysShowCalendars: true,
  autoUpdateInput: false,
  autoApply: true,
  locale: {
    format: 'YYYY-MM-DD'
  },
  maxYear: parseInt(moment().format('YYYY'), 10) + 10,
  minYear: 1901,
  showDropdowns: true,
  singleDatePicker: true
};

export function initDateRangePicker($inputs, options) {
  $inputs.daterangepicker(options);

  // NOTE: assumes `options.autoUpdateInput: false` to allow for initially
  // empty inputs: https://www.daterangepicker.com/#example5
  $inputs.on('apply.daterangepicker', function (e, picker) {
    $(this).
      val(picker.startDate.format('MM/DD/YY') + ' - ' + picker.endDate.format('MM/DD/YY')).
      trigger("change");
  });

  $inputs.on('cancel.daterangepicker', function (e, picker) {
    $(this).
      val('').
      trigger("change");
  });
}

export function initDatePicker($inputs, options) {
  $inputs.daterangepicker(options);

  // NOTE: assumes `options.autoUpdateInput: false` to allow for initially
  // empty inputs: https://www.daterangepicker.com/#example5
  $inputs.on('apply.daterangepicker', function (e, picker) {
    $(this).
      val(picker.startDate.format('YYYY-MM-DD')).
      trigger("change");
  });
}
