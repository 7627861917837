import $ from 'jquery-custom';
import { Collection, Model, View } from 'backbone';

const Field = Model.extend({
  getValueAs: function (type, defaultValue) {
    let value = this.get("value");

    if (this.isHidden()) {
      return undefined;
    }

    if (typeof value === "string") {
      value = value.trim();
    }

    switch(type) {
      case 'integer':
        defaultValue ||= 0;
        return Number.parseInt(value, 10) || defaultValue;
      case 'float':
        defaultValue ||= 0.0;
        return Number.parseFloat(value) || defaultValue;
      case 'array':
        defaultValue ||= [];
        return value || defaultValue;
      default:
        return value;
    }
  },

  hasValueAs: function (type) {
    let value = this.get("value");

    if (this.isHidden()) {
      return false;
    }

    if (typeof value === "string") {
      value = value.trim();
    }

    switch(type) {
      case 'integer':
        value = Number.parseInt(value, 10);
        return Number.isInteger(value);
      case 'float':
        value = Number.parseFloat(value);
        return Number.isFinite(value);
      case 'array':
        return value && value.length > 0;
      default:
        return Boolean(value);
    }
  },

  // assumes value is an Array like for CustomFieldCheckboxGroups
  valueIncludes: function (value) {
    const values = this.getValueAs("array") || [];

    return values.includes(value);
  },

  parentHidden: function () {
    // if parent is hidding and `this` is not already explicitly hidden `this`
    // will become hidden and we should trigger a value change
    if (this.get("show")) {
      this.trigger("change:value", this, undefined);
    }
  },

  parentShown: function () {
    // if parent is showing and `this` is not already explicitly hidden `this`
    // will become visible and we should trigger a value change
    if (this.get("show")) {
      this.trigger("change:value", this, this.get("value"));
    }
  },

  hide: function () {
    if (this.get("show")) { // things to do only if show/hide is changing
      this.set({ show: false });
      this.trigger("change:value", this, undefined);

      this.children().forEach(function (child) {
        child.parentHidden();
      });
    }
  },

  show: function () {
    if (!this.get("show")) { // things to do only if show/hide is changing
      this.set({ show: true });
      this.trigger("change:value", this, this.get("value"));

      this.children().forEach(function (child) {
        child.parentShown();
      });
    }
  },

  hideIf: function (hide) {
    if (hide) {
      this.hide();
    } else {
      this.show();
    }
  },

  showIf: function (show) {
    if (show) {
      this.show();
    } else {
      this.hide();
    }
  },

  isHidden: function () {
    return !this.get("show") || (this.parent() && this.parent().isHidden());
  },

  isShown: function () {
    return this.get("show") && (!this.parent() || this.parent().isShown());
  },

  parent: function () {
    return this.collection.findWhere({serverId: this.get('parentId')});
  },

  children: function () {
    return this.collection.where({ parentId: this.get('serverId') });
  }
});

const Fields = Collection.extend({
  model: Field
});

export const ControlApi = View.extend({
  events: {
    'change [data-id] input:checkbox': 'checkboxChange',
    'click [data-id] input:checkbox': 'checkboxClick',
    'change [data-id] :input:not(:checkbox)': 'inputChange', // TODO: is change enough for all remaining input types?
    'shown.bs.tab': 'showTab',
    'control:todo:to_do_script_loaded': 'toDoScriptLoaded',
    'ajax:error': 'ajaxError'
  },

  initialize: function () {
    this.fields = new Fields;
    this.addFields();
    this.fields.on('change:value', this.updateDomFieldValue, this);
    this.fields.on('change:show', this.updateDomFieldShowHide, this);
  },

  showTab: function () {
    this.triggerChangeValueAll();
    this.triggerChangeShowAll();
  },

  toDoScriptLoaded: function (e) {
    this.triggerChangeValueAll();
  },

  ajaxError: function () {
    // dom now has a fresh form with inline errors displayed so update dom to
    // match show/hide state
    this.triggerChangeShowAll();
  },

  addFields: function () {
    this.fields.add(this.fieldsData(), { merge: false });
    this.triggerChangeValueAll();
  },

  triggerChangeShowAll: function () {
    this.fields.forEach(function (model) {
      model.trigger("change:show", model, model.get("show"));
    });
  },

  triggerChangeValueAll: function () {
    this.fields.forEach(function (model) {
      // trigger change:value for show/hide support
      model.trigger("change:value", model, model.get("value"), { skipDomUpdate: true });
    });
  },

  fieldsData: function () {
    return this.$('form [data-id]').map(this._inputData).toArray();
  },

  checkboxChange: function (event) {
    const $input = $(event.target);
    const id = $input.closest('[data-id]').data("id");
    const field = this.fields.findWhere({ serverId: id });
    const $fieldContainer = this.$(`[data-id="${field.get('serverId')}"]`);
    let values;

    switch(field.get('type')) {
      case 'CustomFieldCheckbox':
        field.set({ value: $input.is(":checked") });
        break;
      case 'CustomFieldCheckboxGroup':
        values = [];

        $fieldContainer.find('input:checkbox:checked').each(function () {
          values.push($(this).val());
        });

        field.set({ value: values });
        break;
      default:
        field.set({ value: $input.is(":checked") });
    }
  },

  checkboxClick: function (event) {
    const $input = $(event.target);
    const id = $input.closest('[data-id]').data("id");
    const field = this.fields.findWhere({ serverId: id });
    const $fieldContainer = this.$(`[data-id="${field.get('serverId')}"]`);
    let values;

    switch(field.get('type')) {
      case 'CustomFieldCheckbox':
        field.set({ value: $input.is(":checked") });
        break;
      case 'CustomFieldCheckboxGroup':
        values = [];

        $fieldContainer.find('input:checkbox:checked').each(function () {
          values.push($(this).val());
        });

        field.set({ value: values });
        break;
      default:
        field.set({ value: $input.is(":checked") });
    }
  },

  inputChange: function (event) {
    const $input = $(event.target);
    const id = $input.closest('[data-id]').data("id");
    const field = this.fields.findWhere({ serverId: id });

    field.set({ value: $input.val() });
  },

  updateDomFieldValue: function (field, value, options) {
    if (options && options.skipDomUpdate) {
      return;
    }

    const $fieldContainer = this.$(`[data-id="${field.get('serverId')}"]`);

    switch(field.get('type')) {
      case 'CustomFieldAttachment':
      case 'CustomFieldControlFile':
      case 'CustomFieldImageAttachment':
        // Do nothing. HTML file inputs cannot be changed via javascript.
        break;
      case 'CustomFieldCheckbox':
        $fieldContainer.find('input:checkbox').prop("checked", value);
        break;
      case 'CustomFieldCheckboxGroup':
        value ||= [];

        $fieldContainer.find('input:checkbox').each(function () {
          const $checkbox = $(this);

          $checkbox.prop("checked", value.includes($checkbox.val()));
        });
        break;
      case 'CustomFieldRadioButton':
        $fieldContainer.find(`input:radio[value="${value}"]`).prop("checked", true);
        break;
      case 'CustomFieldTextDisplay':
      case 'CustomFieldVideoDisplay':
        $fieldContainer.html(value);
        break;
      case 'CustomFieldDeveloperTab':
      case 'CustomFieldFieldset':
      case 'CustomFieldSummaryTab':
      case 'CustomFieldTab':
        // Do Nothing
        break;
      default:
        $fieldContainer.find(":input").val(value);
    }
  },

  updateDomFieldShowHide: function (field, value, options) {
    const $fieldContainer = this.$(`[data-id="${field.get('serverId')}"]`);
    const show = field.get('show');

    $fieldContainer.toggle(show);

    switch(field.get('type')) {
      case 'CustomFieldFieldset':
        // enable/disable the $observer so hidden :inputs are not sent with the form.
        // Disabled fieldsets implicitly disable their contained :inputs
        // https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#concept-fe-disabled
        //
        // use `children` instead of find to avoid disabling extra fieldsets that wrap radio buttons
        $fieldContainer.children('fieldset').prop('disabled', !show);
        break;
      case 'CustomFieldDeveloperTab':
      case 'CustomFieldSummaryTab':
      case 'CustomFieldTab':
        // We do not support show/hide for Tabs
        break;
      default:
        // enable/disable the $fieldContainer so hidden :inputs are not sent with the form.
        $fieldContainer.find(':input').prop('disabled', !show);
    }
  },

  _inputData: function (_index, field) {
    const $field = $(field);
    const data = $.extend(true, {}, $field.data()); // clone data() so setting additional attributes doesn't also update jquery's internal $field object
    const toDoScriptId = data.toDoScriptId && data.toDoScriptId.toString().trim();
    let values;

    // ToDoScriptID becomes the model id if it is present.
    // Allows for `collection.get('some-todoscript-id')`.
    // Save numeric id as serverId for dom references
    data.serverId = data.id;
    if (toDoScriptId) {
      data.id = toDoScriptId;
    }

    switch(data.type) {
      case 'CustomFieldCheckbox':
        data.value = $field.find('input:checkbox').is(":checked");
        break;
      case 'CustomFieldCheckboxGroup':
        values = [];

        $field.find('input:checkbox:checked').each(function () {
          values.push($(this).val());
        });

        data.value = values;
        break;
      case 'CustomFieldRadioButton':
        data.value = $field.find('input:radio:checked').val();
        break;
      case 'CustomFieldTextDisplay':
      case 'CustomFieldVideoDisplay':
        data.value = $field.html();
        break;
      case 'CustomFieldDeveloperTab':
      case 'CustomFieldFieldset':
      case 'CustomFieldSummaryTab':
      case 'CustomFieldTab':
        data.value = undefined;
        break;
      default:
        data.value = $field.find(":input").val();
    }

    // attributes for managing show/hide
    data.show = true;

    return data;
  }
});

window.ControlApi = ControlApi;
export default ControlApi;
