import $ from 'jquery-custom';

$.fn.targetLinksOutOfIframe = function () {
  $(this).find('a:not([href^="#"])').attr({
    'target': '_blank',
    'rel': 'noopener noreferrer'
  });
};

$(function () {
  $('.js-target-blank').targetLinksOutOfIframe();
});

$(document).on('ajax:complete', function () {
  $('.js-target-blank').targetLinksOutOfIframe();
});
