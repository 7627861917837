import $ from 'jquery-custom';

export default function tableOfContents() {
  let nodeName;
  let prevNodeName;
  let currentList = $('<ol>', { class: "list-unstyled ml-4" }).appendTo('#toc');

  $('h1[id], h2[id], h3[id]').each(function () {
    nodeName = this.nodeName;

    if (prevNodeName && prevNodeName < nodeName) { // prevNode "contains" node, create a new sublevel
      currentList = $('<ol>', { class: "list-unstyled ml-4" }).appendTo(currentList.find('li:last'));
    } else if (prevNodeName && prevNodeName > nodeName) { // break out of the current sublevel
      currentList = currentList.parents('ol');
    }

    const item = $('<li>').appendTo(currentList);
    item.append($('<a>', { href: `#${$(this).prop('id')}` }).html($(this).text()));

    prevNodeName = nodeName;
  });
}
