import $ from 'jquery-custom';

// TODO: remove comments and use descriptive method names instead
// TODO: make this only work on visible fields

const autoFillToDo = function () {
  $('checkbox').prop('checked'); // does this actually work?
  $('select').each(function (i, el) { // choose last option in select drop-down
    $(el).val($(el).find('option').last().val());
  });
  $('input[type=radio]').prop('checked', true); // check last radio in each group
  $('input[type=text]:not(.datepicker)').val('test text'); // fill out non-datepicker text fields
  $('textarea:visible').val('test text'); // fill out only visible textareas
};

export default autoFillToDo;
