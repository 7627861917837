import $ from 'jquery-custom';
import _ from 'underscore';

// ## Control JavaScript API

// Built using the JavaScript sandbox pattern, the Control JavaScript
// API provides a consistent and modular developer interface. When
// extending the functionality of ToDos or executing server-side
// operations, the interface is the same.

// Consistent with the JavaScript design pattern of global abatement,
// the `ControlToDo` object is only variable added to the global
// namespace. This serves both to reduce the attack surface of the
// library as well as to prevent conflicts with other libraries.
const ControlToDo = (function ()  {
  const func_factory = function func_factory(name, func) {
    func.displayName = name;
    return func;
  };

  func_factory.displayName = 'func_factory';

  function ControlToDo() {
    const args = _.toArray(arguments);
    // The last argument must always be the callback function
    const callback = args.pop();
    // The first parameter must be an array. If it is anything
    // other than an array (or nothing at all) we'll make it an
    // empty array.
      const modules = (args[0] && _.isArray(args[0])) ? args[0] : [];
    let i;
    let module_error;
    const that = this;

    //Ensure we are calling ControlToDo as a constructor, even if
    //a sloppy developer forgets `new`
    if (!(this instanceof ControlToDo)) {
      return new ControlToDo(modules, callback);
    }

    // Initialize all of the modules passing `this` as the
    // argument. If the module does not exist, throw an error.
    for (i = 0; i < modules.length; i += 1) {
      if (Object.prototype.hasOwnProperty.call(ControlToDo.modules, modules[i])) {
        ControlToDo.modules[modules[i]](this);
      } else {
        module_error = new Error(_.template('MissingModuleError: The module {{ module }} does not exist.')({ module: modules[i] }));
        throw module_error;
      }
    }

    //We always want the setup module to be present as it adds
    //top-level those utilities which are part of the API itself
    //and are therefore always present.
    ControlToDo.modules.setup(this);

    try {
      callback(that); // NOTE: server_message can/will be overridden
    } catch (error) {
      if (typeof Honeybadger === "object") {
        Honeybadger.notify(error);
      }
    }
  }

  // Register some information about the API library version and such.
  ControlToDo.prototype = {
    name: "Continuity Control Framework Sandbox",
    version: "0.1",
    getName: func_factory('getName', function getName() {
      return this.name;
    })
  };

  // Since JavaScript functions are also object, we initialize the
  // modules property to be an empty object. All modules will be
  // added as properties of this object.
  ControlToDo.modules = {};

  // The setup module contains basic utilites added to the framework
  // itself and not to any specific module. This is always
  // initialized so that these utilities are always present.
  ControlToDo.modules.setup = func_factory('setup', function setup(box) {
    // If the jQuery object is present, bind it to the `$`
    // property on the sandbox object. Otherwise, it's an empty
    // object.
    box.$ = $ ? $ : {};
  });

  ControlToDo.func_factory = func_factory;

  // make webkit debugging excellent
  func_factory('ControlToDo', ControlToDo);

  // This is the final return of the ControlToDo function
  // object. Notice that the entire library is actually wrapped in a
  // self-executing function. This function takes the jQuery object
  // as an argument.
  return ControlToDo;
}());

window.ControlToDo = ControlToDo;

export default ControlToDo;
